import { AxiosResponse } from "axios";
import api from "../utils/api";

const sendVerificationSms = async ({
  clientId,
  documentId,
  signInPerson = true,
}: {
  clientId: number;
  documentId: number;
  signInPerson?: boolean;
}): Promise<AxiosResponse<true>> => {
  const url = `/api/DigitalSignature/${clientId}/Document/${documentId}/CreateSmsToSignDocument`;

  const params = new URLSearchParams();

  params.append("signInPerson", `${signInPerson}`);

  const response = await api.post(url, undefined, {
    params,
  });

  return response;
};

const checkCodeValidity = async (data: {
  code: string;
  sha256: string;
}): Promise<
  AxiosResponse<{
    digitalSignature: string;
    clientId: number;
    documentId: number;
    clientFullName: string;
    signedDate: string;
  }>
> => {
  const params = new URLSearchParams();

  params.append("sha256", `${data.sha256}`);
  params.append("code", `${data.code}`);

  const response = await api.put(
    "/api/DigitalSignature/VerifySmsCode",
    undefined,
    {
      params,
    }
  );

  return response;
};

const sendDocumentIsSignBiometrically = async ({
  clientId,
  docuemntId,
}: {
  clientId: number;
  docuemntId: number;
}): Promise<AxiosResponse<true>> =>
  await api.post(
    `/api/DigitalSignature/${clientId}/Document/${docuemntId}/SignDocumentBiometrically`
  );

const sendTimeOfSigning = async (data: {
  sha256: string;
  dateOfSign: string;
}): Promise<AxiosResponse<true>> =>
  await api.put(`/api/DigitalSignature/SubmitRemoteSigning`, data);

const digitalSignatureApi = Object.freeze({
  sendVerificationSms,
  checkCodeValidity,
  sendDocumentIsSignBiometrically,
  sendTimeOfSigning,
});

export default digitalSignatureApi;
