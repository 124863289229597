import { createAction } from "@reduxjs/toolkit";
import { IClientChangeDemands } from "../../models/client";
import { StoreState, StoreStateDontLoad } from "../storeState";
import { ClientChangeDemandListTypes } from "./types";

export const clientChangeDemandsFetchSuccess = createAction<
  {
    data: IClientChangeDemands[];
    newCountState: StoreStateDontLoad;
    newCount: number;
  },
  ClientChangeDemandListTypes.CLIENT_CHANGE_DEMAND_LIST_FETCH_SUCCESS
>(ClientChangeDemandListTypes.CLIENT_CHANGE_DEMAND_LIST_FETCH_SUCCESS);

export const clientChangeDemandsListFetchRequest = createAction<
  StoreStateDontLoad,
  ClientChangeDemandListTypes.CLIENT_CHANGE_DEMAND_LIST_FETCH_REQUEST
>(ClientChangeDemandListTypes.CLIENT_CHANGE_DEMAND_LIST_FETCH_REQUEST);

export const clientChangeDemandsListReset = createAction<
  void,
  ClientChangeDemandListTypes.CLIENT_CHANGE_DEMAND_LIST_RESET
>(ClientChangeDemandListTypes.CLIENT_CHANGE_DEMAND_LIST_RESET);

export const clientChangeDemandsListLoad = createAction<
  {
    newState: StoreState;
    newCountState: StoreStateDontLoad;
  },
  ClientChangeDemandListTypes.CLIENT_CHANGE_DEMAND_LIST_LOAD
>(ClientChangeDemandListTypes.CLIENT_CHANGE_DEMAND_LIST_LOAD);

export const clientChangeDemandsListFetchError = createAction<
  boolean,
  ClientChangeDemandListTypes.CLIENT_CHANGE_DEMAND_LIST_FETCH_ERROR
>(ClientChangeDemandListTypes.CLIENT_CHANGE_DEMAND_LIST_FETCH_ERROR);

export const clientChangeDemandsListCountRequest = createAction<
  void,
  ClientChangeDemandListTypes.CLIENT_CHANGE_DEMAND_LIST_COUNT_REQUEST
>(ClientChangeDemandListTypes.CLIENT_CHANGE_DEMAND_LIST_COUNT_REQUEST);

export const clientChangeDemandsListCountSuccess = createAction<
  number,
  ClientChangeDemandListTypes.CLIENT_CHANGE_DEMAND_LIST_COUNT_SUCCESS
>(ClientChangeDemandListTypes.CLIENT_CHANGE_DEMAND_LIST_COUNT_SUCCESS);

export const clientChangeDemandsListCountError = createAction<
  boolean,
  ClientChangeDemandListTypes.CLIENT_CHANGE_DEMAND_LIST_COUNT_ERROR
>(ClientChangeDemandListTypes.CLIENT_CHANGE_DEMAND_LIST_COUNT_ERROR);

export const clientChangeDemandListPage = createAction<
  number,
  ClientChangeDemandListTypes.CLIENT_CHANGE_DEMAND_LIST_CHANGE_PAGE
>(ClientChangeDemandListTypes.CLIENT_CHANGE_DEMAND_LIST_CHANGE_PAGE);

export const clientChangeDemandsLisOrder = createAction<
  { orderBy: string; orderDesc: boolean },
  ClientChangeDemandListTypes.CLIENT_CHANGE_DEMAND_LIST_CHANGE_ORDER
>(ClientChangeDemandListTypes.CLIENT_CHANGE_DEMAND_LIST_CHANGE_ORDER);
