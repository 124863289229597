import { IClientChangeDemands } from "../../models/client";
import { IChangeDemand } from "../../models/demand";
import { IFilterType } from "../filterType";
import { IListType } from "../listType";

export enum ClientChangeDemandListTypes {
  CLIENT_CHANGE_DEMAND_LIST_FETCH_REQUEST = "@@clientDemandList/CHANGE_DEMAND_LIST_FETCH_REQUEST",
  CLIENT_CHANGE_DEMAND_LIST_COUNT_REQUEST = "@@clientDemandList/CHANGE_DEMAND_LIST_COUNT_FETCH_REQUEST",
  CLIENT_CHANGE_DEMAND_LIST_FETCH_SUCCESS = "@@clientDemandList/CHANGE_DEMAND_LIST_COUNT_FETCH_SUCCESS",
  CLIENT_CHANGE_DEMAND_LIST_FETCH_ERROR = "@@clientDemandList/CHANGE_DEMAND_LIST_FETCH_ERROR",
  CLIENT_CHANGE_DEMAND_LIST_COUNT_SUCCESS = "@@clientDemandList/CHANGE_DEMAND_LIST_COUNT_SUCCESS",
  CLIENT_CHANGE_DEMAND_LIST_COUNT_ERROR = "@@clientDemandList/CHANGE_DEMAND_LIST_COUNT_ERROR",
  CLIENT_CHANGE_DEMAND_LIST_CHANGE_ORDER = "@@clientDemandList/CHANGE_DEMAND_LIST_CHANGE_ORDER",
  CLIENT_CHANGE_DEMAND_LIST_CHANGE_PAGE = "@@clientDemandList/CHANGE_DEMAND_LIST_CHANGE_PAGE",
  CLIENT_CHANGE_DEMAND_LIST_CHANGE_FILTER = "@@clientDemandList/CHANGE_DEMAND_LIST_CHANGE_FILTER",
  CLIENT_CHANGE_DEMAND_LIST_RESET = "@@clientDemandList/CHANGE_DEMAND_LIST_RESET",
  CLIENT_CHANGE_DEMAND_LIST_LOAD = "@@clientDemandList/CHANGE_DEMAND_LIST_LOAD",
}

export interface IClientChangeDemandListState
  extends IListType<IClientChangeDemands, IFilterType> {}
