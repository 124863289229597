import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  IClientChangeDemands,
  IClientCreateChangeDemand,
  IClientDemand,
} from "../models/client";
import api from "../utils/api";

const postClientDemand = async (
  data: IClientDemand
): Promise<AxiosResponse<IClientCreateChangeDemand>> =>
  api.post(`/api/ClientDemand/Demand`, data);

const getMyChangeDemands = async (
  config: AxiosRequestConfig
): Promise<AxiosResponse<IClientChangeDemands[]>> =>
  api.get(`/api/ClientDemand/GetMeList`, config);

const getMyChangeDemandsCount = async (
  config: AxiosRequestConfig
): Promise<
  AxiosResponse<{
    count: number;
  }>
> => api.get(`/api/ClientDemand/GetMeCount`, config);

const getChangeDemand = async (
  demandId: number
): Promise<AxiosResponse<IClientChangeDemands>> =>
  api.get(`/api/ClientDemand/GetMeDemand/${demandId}`);

const clientDemandApi = {
  postClientDemand,
  getMyChangeDemands,
  getMyChangeDemandsCount,
  getChangeDemand,
};

export default clientDemandApi;
