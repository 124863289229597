import { Formik } from "formik";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ClientIdentityType, IClient } from "../../models/client";
import { IApplicationState } from "../../store";
import { selectClient, updateClient } from "../../store/client";
import { ApiError, FormGroup, FormRow } from "../../styles/form";
import Input from "../common/form/Input";
import PhoneInput from "../common/form/PhoneInput";
import * as Yup from "yup";
import clientApi from "../../api/client";
import validations from "../../utils/validations";
import { promiseToastSave } from "../../utils/toasts";
import SubmitForm from "../common/form/SubmitForm";
import DatePicker from "../common/form/DatePicker";
import { handleLoadEmptySuggestions } from "../../utils/suggestions";
import SuggestionFormik from "../common/suggestion/SuggestionFormik";
import { FormDesktop, FormTablet } from "../../styles/form";
import FormGroupRow from "../common/form/FormGroupRow";
import { errorSet } from "../../utils/error";
import { H1WithMargin } from "../../styles/text";
import styled from "styled-components";
import { FormButton } from "../../styles/button";
import bucketApi from "../../api/bucket";
import clientDemandApi from "../../api/clientDemand";
import { IClientDemandType } from "../../models/demand";
import { usePromiseModal } from "../../context/PromiseModalContext";

const FileField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ccc;
  width: auto;
  padding: 10px;
  gap: 10px;
  cursor: pointer;
`;

interface IClientForm
  extends Omit<
    IClient,
    | "addressPermanentHouseNumber"
    | "addressPermanentLandRegistryNumber"
    | "addressPermanentPostCode"
    | "addressActualHouseNumber"
    | "addressActualLandRegistryNumber"
    | "addressActualPostCode"
    | "addressMailHouseNumber"
    | "addressMailLandRegistryNumber"
    | "addressMailPostCode"
  > {
  keyCaregiverId?: number;
  keyCaregiverName?: string;
  healthInsuranceId: number;
  healthInsuranceName: string;
  addressPermanentHouseNumber: string;
  addressPermanentLandRegistryNumber: string;
  addressPermanentPostCode: string;
  addressActualHouseNumber: string;
  addressActualLandRegistryNumber: string;
  addressActualPostCode: string;
  addressMailHouseNumber: string;
  addressMailLandRegistryNumber: string;
  addressMailPostCode: string;
}

interface IProps {
  client: IClient | null;
  updateClient(newData: IClient): void;
}

// used as 2 parts in FormDesktop using slice
const fields1: Array<{ name: keyof IClient; maxLen?: number }> = [
  { name: "title", maxLen: 100 },
  { name: "firstName", maxLen: 100 },
  { name: "lastName", maxLen: 100 },
  { name: "maidenName", maxLen: 100 },
  { name: "degree", maxLen: 100 },
];

// used as 2 parts in FormDesktop using slice
const addressPermanentFields: Array<{ name: keyof IClient; maxLen?: number }> =
  [
    { name: "addressPermanentStreet", maxLen: 150 },
    { name: "addressPermanentHouseNumber" },
    { name: "addressPermanentLandRegistryNumber" },
    { name: "addressPermanentCity", maxLen: 150 },
    { name: "addressPermanentPostCode" },
  ];

const numberFields: Array<keyof IClient> = [
  "addressPermanentHouseNumber",
  "addressPermanentLandRegistryNumber",
  "addressPermanentPostCode",
  "addressActualHouseNumber",
  "addressActualPostCode",
  "addressActualLandRegistryNumber",
  "addressMailHouseNumber",
  "addressMailPostCode",
  "addressMailLandRegistryNumber",
];

const addressActualFields: Array<{
  name: keyof IClient;
  maxLen?: number;
}> = [
  { name: "addressActualStreet", maxLen: 150 },
  { name: "addressActualHouseNumber" },
  { name: "addressActualLandRegistryNumber" },
  { name: "addressActualCity", maxLen: 150 },
  { name: "addressActualPostCode" },
];

const addressMailFields: Array<{
  name: keyof IClient;
  maxLen?: number;
}> = [
  { name: "addressMailStreet", maxLen: 150 },
  { name: "addressMailHouseNumber" },
  { name: "addressMailLandRegistryNumber" },
  { name: "addressMailCity", maxLen: 150 },
  { name: "addressMailPostCode" },
];

const ClientGeneral: FC<IProps> = ({ client, updateClient }) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const fileInput = useRef<HTMLInputElement>(null);
  const { openModal } = usePromiseModal();

  const handleSubmitRequestDataChange = async (data: IClientForm) => {
    if (!client) return;

    const newData: IClientDemandType = {
      firstName: data.firstName,
      lastName: data.lastName,
      maidenName: data.maidenName || null,
      title: data.title || null,
      degree: data.degree || null,
      mobilePhone: data.mobilePhone || null,
      phone: data.phone || null,
      email: data.email || null,
      dataBox: data.dataBox || null,
      identityType: data.identityType || null,
      identityCode: data.identityCode || null,
      birthDate: data.birthDate || null,
      createdAt: new Date() || null,
      finishedAt: null,
      permanentAddress: {
        street: data.addressPermanentStreet,
        houseNumber: data.addressPermanentHouseNumber,
        landRegistryNumber: data.addressPermanentLandRegistryNumber,
        city: data.addressPermanentCity,
        postCode: data.addressPermanentPostCode,
      },
      actualAddress: {
        street: data.addressActualStreet,
        houseNumber: data.addressActualHouseNumber,
        landRegistryNumber: data.addressActualLandRegistryNumber,
        city: data.addressActualCity,
        postCode: data.addressActualPostCode,
      },
      mailAddress: {
        street: data.addressMailStreet,
        houseNumber: data.addressMailHouseNumber,
        landRegistryNumber: data.addressMailLandRegistryNumber,
        city: data.addressMailCity,
        postCode: data.addressMailPostCode,
      },
      addressActualIsSame: data.addressActualIsSame,
      addressMailIsSame: data.addressMailIsSame,
      healthInsuranceId: data.healthInsuranceId || null,
      clientDemandFiles: null,
    };

    const newFiles = uploadedFiles.filter(
      (file) => file.size / 1024 / 1024 < 5
    );

    if (newFiles.length > 0) {
      newData["clientDemandFiles"] = newFiles.map((file) => ({
        fileName: file.name,
        size: file.size,
        uploadedAt: new Date(),
      }));
    }

    const demand = await clientDemandApi.postClientDemand(newData);

    setIsEditing(false);

    if (!demand.data.uploadUrls) return;

    demand.data.uploadUrls.forEach(async ({ url, filename }) => {
      const file = uploadedFiles.find((file) => file.name === filename);

      if (!file) return;

      await bucketApi.uploadDocument(url, file);
    });
  };

  const bothKeyCareGiverFieldsAreEmpty =
    !!client?.keyCaregiver?.showMobilePhoneNumber ||
    !!client?.keyCaregiver?.showEmail;

  const KeyCareGiverContactContainer = styled.div`
    display: ${bothKeyCareGiverFieldsAreEmpty ? "flex" : "none"};
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 5px;

    & > span {
      display: flex;
      justify-content: flex-start;
      gap: 10px;

      & > * {
        padding: 0;
        margin: 0;
      }
    }
  `;

  const handleSubmit = async (data: IClientForm) => {
    if (isEditing) {
      openModal(
        t("client.changeDemandConfirmation"),
        () => promiseToastSave(async () => handleSubmitRequestDataChange(data)),
        () => {}
      );

      return;
    }

    setError(null);
    try {
      await promiseToastSave(async () => {
        const data2 = {
          ...data,
          addressPermanentHouseNumber: +data.addressPermanentHouseNumber,
          addressPermanentLandRegistryNumber:
            +data.addressPermanentLandRegistryNumber,
          addressPermanentPostCode: +data.addressPermanentPostCode,
          addressActualHouseNumber: +data.addressActualHouseNumber,
          addressActualLandRegistryNumber:
            +data.addressActualLandRegistryNumber,
          addressActualPostCode: +data.addressActualPostCode,
          addressMailHouseNumber: +data.addressMailHouseNumber,
          addressMailLandRegistryNumber: +data.addressMailLandRegistryNumber,
          addressMailPostCode: +data.addressMailPostCode,
        };

        const newData = await clientApi.updateClient(data2);
        updateClient(newData.data);
      });
    } catch (err) {
      errorSet(setError, err, t);
    }
  };

  const ErrorAndButtons: FC = () => {
    return (
      <>
        {error && <ApiError>{error}</ApiError>}
        <div
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          {!isEditing && <SubmitForm />}
          {!isEditing ? (
            <FormButton
              type="button"
              onClick={() => {
                setIsEditing(true);
              }}
              ver="secondary"
            >
              {t("common.edit")}
            </FormButton>
          ) : (
            <>
              <FormButton type="submit">
                {t("common.ask_to_approve")}
              </FormButton>
              <FormButton
                ver="secondary"
                type="button"
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                {t("common.cancel")}
              </FormButton>
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <Formik<IClientForm>
      initialValues={{
        ...client!,
        keyCaregiverId: client!.keyCaregiver ? 1 : 0,
        keyCaregiverName: client!.keyCaregiver?.name ?? "",
        title: client!.title ?? "",
        degree: client!.degree ?? "",
        mobilePhone: client!.mobilePhone ?? "",
        email: client!.email ?? "",
        phone: client!.phone ?? "",
        dataBox: client!.dataBox ?? "",
        identityCode: client!.identityCode ?? "",
        addressPermanentStreet: client!.addressPermanentStreet ?? "",
        addressPermanentHouseNumber: client!.addressPermanentHouseNumber
          ? client!.addressPermanentHouseNumber.toString()
          : "",
        addressPermanentLandRegistryNumber: client!
          .addressPermanentLandRegistryNumber
          ? client!.addressPermanentLandRegistryNumber.toString()
          : "",
        addressPermanentCity: client!.addressPermanentCity ?? "",
        addressPermanentPostCode: client!.addressPermanentPostCode
          ? client!.addressPermanentPostCode.toString()
          : "",
        addressActualStreet: client!.addressActualStreet ?? "",
        addressActualHouseNumber: client!.addressActualHouseNumber
          ? client!.addressActualHouseNumber.toString()
          : "",
        addressActualLandRegistryNumber: client!.addressActualLandRegistryNumber
          ? client!.addressActualLandRegistryNumber.toString()
          : "",
        addressActualCity: client!.addressActualCity ?? "",
        addressActualPostCode: client!.addressActualPostCode
          ? client!.addressActualPostCode.toString()
          : "",
        addressMailStreet: client!.addressMailStreet ?? "",
        addressMailHouseNumber: client!.addressMailHouseNumber
          ? client!.addressMailHouseNumber.toString()
          : "",
        addressMailLandRegistryNumber: client!.addressMailLandRegistryNumber
          ? client!.addressMailLandRegistryNumber.toString()
          : "",
        addressMailCity: client!.addressMailCity ?? "",
        addressMailPostCode: client!.addressMailPostCode
          ? client!.addressMailPostCode.toString()
          : "",
        healthInsuranceId: client!.healthInsurance ? 1 : 0,
        healthInsuranceName: client!.healthInsurance ?? "",
      }}
      key={`form_${isEditing}`}
      enableReinitialize={true}
      validationSchema={Yup.object({
        firstName: validations.stringRequired(t),
        lastName: validations.stringRequired(t),
        mobilePhone: validations.phoneOptional(t),
        email: validations.emailRequired(t),
        phone: validations.phoneOptional(t),
        addressPermanentHouseNumber: validations.intOptionalMinMax(1, 9999, t),
        addressPermanentPostCode: validations.postCodeOptional(t),
        addressActualHouseNumber: validations
          .intOptional(t)
          .when(["addressActualIsSame"], {
            is: (addressActualIsSame: any) => !addressActualIsSame,
            then: validations.intOptionalMinMax(1, 9999, t),
          }),
        addressActualPostCode: validations
          .intOptional(t)
          .when(["addressActualIsSame"], {
            is: (addressActualIsSame: any) => !addressActualIsSame,
            then: validations.postCodeOptional(t),
          }),
        addressMailHouseNumber: validations
          .intOptional(t)
          .when(["addressMailIsSame"], {
            is: (addressMailIsSame: any) => !addressMailIsSame,
            then: validations.intOptionalMinMax(1, 9999, t),
          }),
        addressMailPostCode: validations
          .intOptional(t)
          .when(["addressMailIsSame"], {
            is: (addressMailIsSame: any) => !addressMailIsSame,
            then: validations.postCodeOptional(t),
          }),
        identificationNumber: validations.identificationNumberOptional(t),
      })}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, values }) => (
        <>
          <H1WithMargin>{t("client.general")}</H1WithMargin>
          <FormDesktop>
            <FormRow gridTemplateColumns="1fr 2fr 2fr 2fr 1fr">
              {fields1.map((f) => (
                <Input
                  showInitialValue={isEditing}
                  key={f.name}
                  name={f.name}
                  type={
                    numberFields.find((x) => x === f.name) ? "number" : "text"
                  }
                  label={t("client." + f.name)}
                  error={touched[f.name] && !!errors[f.name]}
                  maxLength={f.maxLen}
                  disabled={!isEditing}
                />
              ))}
            </FormRow>
            <hr />
            <FormGroup>
              <h2>{t("client.addressPermanent")}</h2>
              <FormGroupRow
                gridTemplateColumns="2fr 1fr 1fr"
                names={[
                  addressPermanentFields[0].name,
                  addressPermanentFields[1].name,
                ]}
                label={t("client.streetAndHouseNumberAndLandRegistryNumber")}
              >
                {addressPermanentFields.slice(0, 3).map((f) => (
                  <Input
                    showInitialValue={isEditing}
                    key={f.name}
                    name={f.name}
                    type={
                      numberFields.find((x) => x === f.name) ? "number" : "text"
                    }
                    error={touched[f.name] && !!errors[f.name]}
                    maxLength={f.maxLen}
                    disabled={!isEditing}
                  />
                ))}
              </FormGroupRow>

              <FormGroupRow
                gridTemplateColumns="2fr 1fr"
                names={[
                  addressPermanentFields[2].name,
                  addressPermanentFields[3].name,
                ]}
                label={t("client.cityAndPostCode")}
              >
                {addressPermanentFields.slice(3).map((f) => (
                  <Input
                    showInitialValue={isEditing}
                    key={f.name}
                    name={f.name}
                    type={
                      numberFields.find((x) => x === f.name) ? "number" : "text"
                    }
                    error={touched[f.name] && !!errors[f.name]}
                    maxLength={f.maxLen}
                    disabled={!isEditing}
                  />
                ))}
              </FormGroupRow>

              <Input
                showInitialValue={isEditing}
                key="addressActualIsSame"
                name="addressActualIsSame"
                type="checkbox"
                label={t(`client.addressActualIsSame`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
                disabled={!isEditing}
              />
              <Input
                showInitialValue={isEditing}
                key="addressMailIsSame"
                name="addressMailIsSame"
                type="checkbox"
                label={t(`client.addressMailIsSame`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
                disabled={!isEditing}
              />
            </FormGroup>
            {!values.addressActualIsSame && (
              <>
                <hr />

                <FormGroup>
                  <h2>{t("client.addressActual")}</h2>
                  <FormGroupRow
                    gridTemplateColumns="2fr 1fr 1fr"
                    names={[
                      addressActualFields[0].name,
                      addressActualFields[1].name,
                      addressActualFields[2].name,
                    ]}
                    label={t(
                      "client.streetAndHouseNumberAndLandRegistryNumber"
                    )}
                  >
                    {addressActualFields.slice(0, 3).map((f) => (
                      <Input
                        showInitialValue={isEditing}
                        key={f.name}
                        name={f.name}
                        type={
                          numberFields.find((x) => x === f.name)
                            ? "number"
                            : "text"
                        }
                        error={touched[f.name] && !!errors[f.name]}
                        maxLength={f.maxLen}
                        disabled={!isEditing}
                      />
                    ))}
                  </FormGroupRow>

                  <FormGroupRow
                    gridTemplateColumns="2fr 1fr"
                    names={[
                      addressActualFields[2].name,
                      addressActualFields[3].name,
                    ]}
                    label={t("client.cityAndPostCode")}
                  >
                    {addressActualFields.slice(3).map((f) => (
                      <Input
                        showInitialValue={isEditing}
                        key={f.name}
                        name={f.name}
                        type={
                          numberFields.find((x) => x === f.name)
                            ? "number"
                            : "text"
                        }
                        error={touched[f.name] && !!errors[f.name]}
                        maxLength={f.maxLen}
                        disabled={!isEditing}
                      />
                    ))}
                  </FormGroupRow>
                </FormGroup>
              </>
            )}
            {!values.addressMailIsSame && (
              <>
                <hr />

                <FormGroup>
                  <h2>{t("client.addressMail")}</h2>
                  <FormGroupRow
                    gridTemplateColumns="2fr 1fr 1fr"
                    names={[
                      addressMailFields[0].name,
                      addressMailFields[1].name,
                      addressMailFields[2].name,
                    ]}
                    label={t(
                      "client.streetAndHouseNumberAndLandRegistryNumber"
                    )}
                  >
                    {addressMailFields.slice(0, 3).map((f) => (
                      <Input
                        showInitialValue={isEditing}
                        key={f.name}
                        name={f.name}
                        type={
                          numberFields.find((x) => x === f.name)
                            ? "number"
                            : "text"
                        }
                        error={touched[f.name] && !!errors[f.name]}
                        maxLength={f.maxLen}
                        disabled={!isEditing}
                      />
                    ))}
                  </FormGroupRow>

                  <FormGroupRow
                    gridTemplateColumns="2fr 1fr"
                    names={[
                      addressMailFields[2].name,
                      addressMailFields[3].name,
                    ]}
                    label={t("client.cityAndPostCode")}
                  >
                    {addressMailFields.slice(3).map((f) => (
                      <Input
                        showInitialValue={isEditing}
                        key={f.name}
                        name={f.name}
                        type={
                          numberFields.find((x) => x === f.name)
                            ? "number"
                            : "text"
                        }
                        error={touched[f.name] && !!errors[f.name]}
                        maxLength={f.maxLen}
                        disabled={!isEditing}
                      />
                    ))}
                  </FormGroupRow>
                </FormGroup>
              </>
            )}
            <hr />
            <FormGroup>
              <h2>{t("client.contact")}</h2>
              <PhoneInput
                name="mobilePhone"
                label={t("client.mobilePhone")}
                error={touched.mobilePhone && !!errors.mobilePhone}
              />
              <PhoneInput
                name="phone"
                label={t("client.phone")}
                error={touched.phone && !!errors.phone}
              />
              <Input
                showInitialValue={isEditing}
                name="email"
                label={t("client.email")}
                error={touched.email && !!errors.email}
                maxLength={255}
              />
              <Input
                showInitialValue={isEditing}
                name="dataBox"
                label={t("client.dataBox")}
                error={touched.dataBox && !!errors.dataBox}
                maxLength={100}
              />
            </FormGroup>
            <hr />
            <FormGroup>
              <h2>{t("client.identification")}</h2>
              <FormGroupRow
                gridTemplateColumns="1fr 1fr"
                names={["identityType", "identityCode"]}
                label={t("client.identification")}
              >
                <Input
                  showInitialValue={isEditing}
                  key="identityType"
                  name="identityType"
                  component="select"
                  error={touched.identityType && !!errors.identityType}
                  disabled={!isEditing}
                >
                  <option value="">{t("client.identityTypes.null")}</option>
                  {(
                    Object.keys(ClientIdentityType) as Array<
                      keyof typeof ClientIdentityType
                    >
                  ).map((key) => (
                    <option key={key} value={ClientIdentityType[key]}>
                      {t("client.identityTypes." + ClientIdentityType[key])}
                    </option>
                  ))}
                </Input>
                {values.identityType &&
                  values.identityType !== ClientIdentityType.Unverified && (
                    <Input
                      showInitialValue={isEditing}
                      key="identityCode"
                      name="identityCode"
                      error={touched.identityCode && !!errors.identityCode}
                      maxLength={100}
                      disabled={!isEditing}
                    />
                  )}
              </FormGroupRow>

              <FormGroupRow
                gridTemplateColumns="1fr 1fr"
                names={["birthDate", "identificationNumber"]}
                label={t("client.birdthDataAndIdentificationNumber")}
              >
                <DatePicker name="birthDate" disabled />
                <Input
                  showInitialValue={isEditing}
                  key="identificationNumber"
                  name="identificationNumber"
                  error={
                    touched.identificationNumber &&
                    !!errors.identificationNumber
                  }
                  disabled
                />
              </FormGroupRow>

              <SuggestionFormik
                nameId="healthInsuranceId"
                nameText="healthInsuranceName"
                label={t("client.healthInsurance")}
                loadSuggestions={handleLoadEmptySuggestions}
                disabled={!isEditing}
                placeholder={t("common.suggestionNoSelected")}
              />

              <SuggestionFormik
                nameId="keyCaregiverId"
                nameText="keyCaregiverName"
                label={t("client.keyCaregiver")}
                loadSuggestions={handleLoadEmptySuggestions}
                disabled={!isEditing}
                placeholder={t("common.suggestionNoSelected")}
              />
            </FormGroup>
            <KeyCareGiverContactContainer>
              <h2>{t("client.keyCaregiverContact")}</h2>
              {client?.keyCaregiver?.showMobilePhoneNumber && (
                <span>
                  <label>{t("client.mobilePhone") + ": "}</label>
                  <a href={`tel:${client?.keyCaregiver?.mobileNumber}`}>
                    {client?.keyCaregiver?.mobileNumber}
                  </a>
                </span>
              )}
              <br />
              {client?.keyCaregiver?.showEmail && (
                <span>
                  <label>{t("client.email") + ": "}</label>
                  <a href={`mailto:${client?.keyCaregiver?.email}`}>
                    {client?.keyCaregiver?.email}
                  </a>
                </span>
              )}
            </KeyCareGiverContactContainer>

            {isEditing && (
              <>
                <FileField onClick={() => fileInput.current?.click()}>
                  <label>{t("client.selectFile")}</label>
                  <input
                    type="file"
                    onChange={(e) => {
                      if (
                        e.target &&
                        e.target.files &&
                        e.target.files.length > 0
                      ) {
                        setUploadedFiles((prev) => [
                          ...prev,
                          // @ts-ignore
                          e.target.files[0],
                        ]);
                      }
                    }}
                    ref={fileInput}
                    hidden
                  />
                </FileField>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  {uploadedFiles.map((file) => (
                    <div
                      key={file.name}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <span>{file.name}</span>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setUploadedFiles((prev) =>
                            prev.filter((f) => f.name !== file.name)
                          )
                        }
                      >
                        x
                      </span>
                    </div>
                  ))}
                </div>
              </>
            )}
            <ErrorAndButtons />
          </FormDesktop>

          <FormTablet>
            <FormGroup>
              {fields1.map((f) => (
                <Input
                  showInitialValue={isEditing}
                  key={f.name}
                  name={f.name}
                  type={
                    numberFields.find((x) => x === f.name) ? "number" : "text"
                  }
                  label={t("client." + f.name)}
                  error={touched[f.name] && !!errors[f.name]}
                  maxLength={f.maxLen}
                  disabled={!isEditing}
                />
              ))}
            </FormGroup>

            <hr />

            <FormGroup>
              <h2>{t("client.addressPermanent")}</h2>
              {addressPermanentFields.map((f) => (
                <Input
                  showInitialValue={isEditing}
                  key={f.name}
                  name={f.name}
                  type={
                    numberFields.find((x) => x === f.name) ? "number" : "text"
                  }
                  label={t("client." + f.name)}
                  error={touched[f.name] && !!errors[f.name]}
                  maxLength={f.maxLen}
                  disabled={!isEditing}
                />
              ))}
              <Input
                showInitialValue={isEditing}
                key="addressActualIsSame"
                name="addressActualIsSame"
                type="checkbox"
                label={t(`client.addressActualIsSame`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
                disabled={!isEditing}
              />
              <Input
                showInitialValue={isEditing}
                key="addressMailIsSame"
                name="addressMailIsSame"
                type="checkbox"
                label={t(`client.addressMailIsSame`)}
                inputWidth="1.5rem"
                inputHeight="1.5rem"
                disabled={!isEditing}
              />
            </FormGroup>

            {!values.addressActualIsSame && (
              <>
                <hr />

                <FormGroup>
                  <h2>{t("client.addressActual")}</h2>
                  {addressActualFields.map((f) => (
                    <Input
                      showInitialValue={isEditing}
                      key={f.name}
                      name={f.name}
                      type={
                        numberFields.find((x) => x === f.name)
                          ? "number"
                          : "text"
                      }
                      label={t("client." + f.name)}
                      error={touched[f.name] && !!errors[f.name]}
                      maxLength={f.maxLen}
                      disabled={!isEditing}
                    />
                  ))}
                </FormGroup>
              </>
            )}

            {!values.addressMailIsSame && (
              <>
                <hr />

                <FormGroup>
                  <h2>{t("client.addressMail")}</h2>
                  {addressMailFields.map((f) => (
                    <Input
                      showInitialValue={isEditing}
                      key={f.name}
                      name={f.name}
                      type={
                        numberFields.find((x) => x === f.name)
                          ? "number"
                          : "text"
                      }
                      label={t("client." + f.name)}
                      error={touched[f.name] && !!errors[f.name]}
                      maxLength={f.maxLen}
                      disabled={!isEditing}
                    />
                  ))}
                </FormGroup>
              </>
            )}

            <hr />

            <FormGroup>
              <h2>{t("client.contact")}</h2>
              <PhoneInput
                name="mobilePhone"
                label={t("client.mobilePhone")}
                error={touched.mobilePhone && !!errors.mobilePhone}
              />
              <PhoneInput
                name="phone"
                label={t("client.phone")}
                error={touched.phone && !!errors.phone}
              />
              <Input
                showInitialValue={isEditing}
                name="email"
                label={t("client.email")}
                error={touched.email && !!errors.email}
                maxLength={255}
              />
              <Input
                showInitialValue={isEditing}
                name="dataBox"
                label={t("client.dataBox")}
                error={touched.dataBox && !!errors.dataBox}
                maxLength={100}
              />
            </FormGroup>

            <hr />

            <FormGroup>
              <h2>{t("client.identification")}</h2>
              <Input
                showInitialValue={isEditing}
                key="identityType"
                name="identityType"
                component="select"
                label={t("client.identityType")}
                error={touched.identityType && !!errors.identityType}
                disabled={!isEditing}
              >
                <option value="">{t("client.identityTypes.null")}</option>
                {(
                  Object.keys(ClientIdentityType) as Array<
                    keyof typeof ClientIdentityType
                  >
                ).map((key) => (
                  <option key={key} value={ClientIdentityType[key]}>
                    {t("client.identityTypes." + ClientIdentityType[key])}
                  </option>
                ))}
              </Input>
              {values.identityType &&
                values.identityType !== ClientIdentityType.Unverified && (
                  <Input
                    showInitialValue={isEditing}
                    key="identityCode"
                    name="identityCode"
                    label={t("client.identityCode")}
                    error={touched.identityCode && !!errors.identityCode}
                    maxLength={100}
                    disabled={!isEditing}
                  />
                )}
              <DatePicker
                name="birthDate"
                label={t("client.birthDate")}
                disabled={!isEditing}
              />
              <Input
                showInitialValue={isEditing}
                key="identificationNumber"
                name="identificationNumber"
                label={t("client.identificationNumber")}
                error={
                  touched.identificationNumber && !!errors.identificationNumber
                }
                disabled={!isEditing}
              />
              <SuggestionFormik
                nameId="healthInsuranceId"
                nameText="healthInsuranceName"
                label={t("client.healthInsurance")}
                loadSuggestions={handleLoadEmptySuggestions}
                disabled={!isEditing}
                placeholder={t("common.suggestionNoSelected")}
              />
              <SuggestionFormik
                nameId="keyCaregiverId"
                nameText="keyCaregiverName"
                label={t("client.keyCaregiver")}
                loadSuggestions={handleLoadEmptySuggestions}
                disabled={!isEditing}
                placeholder={t("common.suggestionNoSelected")}
              />
            </FormGroup>
            <ErrorAndButtons />
          </FormTablet>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    client: selectClient(state),
  };
};

const mapDispachToProps = {
  updateClient,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientGeneral);
