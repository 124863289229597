import { FC } from "react";
import GlobalStyles from "../../styles/globalStyles";
import { toast, ToastContainer } from "react-toastify";
import IdleHandler from "./IdleHandler";
import "../../utils/i18n";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-modal/styles.css";
import { TOAST_AUTOCLOSE_DEFAULT } from "../../utils/consts";
import { PromiseModalProvider } from "../../context/PromiseModalContext";

const Providers: FC = ({ children }) => {
  return (
    <>
      {/* @ts-ignore */}
      <GlobalStyles />
      <ToastContainer
        theme="colored"
        position={toast.POSITION.BOTTOM_RIGHT}
        autoClose={TOAST_AUTOCLOSE_DEFAULT}
      />
      <IdleHandler />
      <PromiseModalProvider>{children}</PromiseModalProvider>
    </>
  );
};

export default Providers;
