import { IClientChangeDemands } from "../../models/client";
import { StoreState } from "../storeState";

export enum ChangeDemandTypes {
  CHANGE_DEMAND_FETCH_REQUEST = "CHANGE_DEMAND_FETCH_REQUEST",
  CHANGE_DEMAND_FETCH_SUCCESS = "CHANGE_DEMAND_FETCH_SUCCESS",
  CHANGE_DEMAND_FETCH_ERROR = "CHANGE_DEMAND_FETCH_ERROR",
}

export interface IChangeDemandState {
  state: StoreState;
  data: IClientChangeDemands | null;
}
