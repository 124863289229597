import { createAction } from "@reduxjs/toolkit";
import { ChangeDemandTypes } from "./types";

export const changeDemandFetchRequest = createAction<
  void,
  ChangeDemandTypes.CHANGE_DEMAND_FETCH_REQUEST
>(ChangeDemandTypes.CHANGE_DEMAND_FETCH_REQUEST);

export const changeDemandFetchSuccess = createAction<
  any,
  ChangeDemandTypes.CHANGE_DEMAND_FETCH_SUCCESS
>(ChangeDemandTypes.CHANGE_DEMAND_FETCH_SUCCESS);

export const changeDemandFetchError = createAction<
  void,
  ChangeDemandTypes.CHANGE_DEMAND_FETCH_ERROR
>(ChangeDemandTypes.CHANGE_DEMAND_FETCH_ERROR);
