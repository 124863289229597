import Providers from "./components/layout/Providers";
import React, { FC, Suspense } from "react";
import BrowserRouterWithHistory from "./components/common/BrowserRouterWithHistory";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createRootReducer } from "./store";
import { Routes1 } from "./Routes1";

export const App: FC = () => {
  let store;
  if (process.env.REACT_APP_REDUX_DEVTOOLS === "true") {
    const composeEnhancers =
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    store = createStore(
      createRootReducer,
      composeEnhancers(applyMiddleware(thunk))
    );
  } else {
    store = createStore(createRootReducer, applyMiddleware(thunk));
  }

  return (
    <Suspense fallback="loading">
      {/* @ts-ignore */}
      <Provider store={store}>
        <BrowserRouterWithHistory>
          <Providers>
            <Routes1 />
          </Providers>
        </BrowserRouterWithHistory>
      </Provider>
    </Suspense>
  );
};
