import {
  faAddressCard,
  faBars,
  faCalendarAlt,
  faFileWord,
  faHandHoldingHeart,
  faHome,
  faKey,
  faList,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Routes, Route } from "react-router";
import { IClient } from "../../models/client";
import { IApplicationState } from "../../store";
import { selectClient } from "../../store/client";
import AuthRoute from "../auth/AuthRoute";
import { SidebarPageContainer } from "../layout/LayoutStyles";
import Page from "../layout/Page";
import Sidebar from "../layout/Sidebar";
import ClientCare from "./ClientCare";
import ClientCareDetail from "./ClientCareDetail";
import ClientDemand from "./ClientDemand";
import ClientDemandDetail from "./ClientDemandDetail";
import ClientDocument from "./ClientDocument";
import ClientGeneral from "./ClientGeneral";
import ClientHouse from "./ClientHouse";
import ClientPerson from "./ClientPerson";
import ClientPersonDetail from "./ClientPersonDetail";
import ChangeDemand from "./ChangeDemand";
import {
  TopPanel,
  TopPanelClient,
  TopPanelContactPerson,
} from "../common/TopPanelStyles";
import ClientCalendar from "./ClientCalendar";
import formatters from "../../utils/formatters";
import ChangePassword from "../auth/ChangePassword";
import { HeaderIconButton } from "../layout/HeaderStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeaderMenu from "../layout/HeaderMenu";

const links = [
  { id: "general", path: "", icon: faAddressCard },
  { id: "person", path: "person", icon: faUsers },
  { id: "house", path: "house", icon: faHome },
  { id: "care", path: "care", icon: faHandHoldingHeart },
  { id: "calendar", path: "calendar", icon: faCalendarAlt },
  { id: "demand", path: "demand", icon: faList },
  { id: "document", path: "document", icon: faFileWord },
  { id: "change-password", path: "change-password", icon: faKey },
];

const routes = [
  {
    path: "person",
    component: <ClientPerson />,
  },
  {
    path: "person/:personId",
    component: <ClientPersonDetail />,
  },
  {
    path: "house",
    component: <ClientHouse />,
  },
  {
    path: "care",
    component: <ClientCare />,
  },
  {
    path: "care/:careId",
    component: <ClientCareDetail />,
  },
  {
    path: "calendar",
    component: <ClientCalendar />,
  },
  {
    path: "demand",
    component: <ClientDemand />,
  },
  {
    path: "demand/:demandId",
    component: <ClientDemandDetail />,
  },
  {
    path: "document",
    component: <ClientDocument />,
  },
  {
    path: "document/:folderId",
    component: <ClientDocument />,
  },
  {
    path: "change-password",
    component: <ChangePassword />,
  },
  {
    path: "changeDemand/:demandId",
    component: <ChangeDemand />,
  },
  {
    path: "*",
    component: <ClientGeneral />,
  },
];

interface IProps {
  client: IClient | null;
}

const ClientDetail: FC<IProps> = ({ client }) => {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);

  const sidebarLinks = links.map((l) => ({
    key: l.id,
    to: `/${l.path}`,
    text: t(`client.nav.${l.id}`),
    icon: l.icon,
  }));

  return (
    <>
      <HeaderMenu
        open={openMenu}
        setOpen={setOpenMenu}
        sidebarLinks={sidebarLinks}
      />
      <TopPanel>
        <HeaderIconButton onClick={() => setOpenMenu((prev) => !prev)}>
          <FontAwesomeIcon icon={faBars} size="2x" />
        </HeaderIconButton>
        <TopPanelClient>{formatters.fullName(client!)}</TopPanelClient>
        {client!.contactPerson && (
          <TopPanelContactPerson>
            <div>{t("client.logged")}</div>
            <div>{formatters.fullName(client!.contactPerson!)}</div>
          </TopPanelContactPerson>
        )}
      </TopPanel>
      <SidebarPageContainer>
        <Sidebar
          title={t("header.mainMenu")}
          showTitle={false}
          links={sidebarLinks}
        />
        <Page>
          <Routes>
            {routes.map((r) => (
              <Route
                key={r.path}
                path={r.path}
                element={<AuthRoute>{r.component}</AuthRoute>}
              />
            ))}
          </Routes>
        </Page>
      </SidebarPageContainer>
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    client: selectClient(state),
  };
};

export default connect(mapStateToProps)(ClientDetail);
