import { AxiosResponse } from "axios";

import api from "../utils/api";

const getDocumentBytes = async ({
  documentId,
  clientId,
}: {
  documentId: number;
  clientId: number;
}): Promise<AxiosResponse<string>> =>
  api.get(`/api/Document/${documentId}/Client/${clientId}/Download`);

const documentApi = {
  getDocumentBytes,
};

export default documentApi;
