import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons"; // Import the "sign-out-alt" icon
import { FC } from "react";
import { ISidebarLink } from "../../models/common";
import { COLORS } from "../../styles/colors";
import {
  Logout,
  SidebarContainer,
  SidebarHeader,
  SidebarLink,
  SidebarLinks,
} from "./SidebarStyles";
import { logout } from "../../utils/storage";
import { useTranslation } from "react-i18next";

interface IProps {
  title: string;
  showTitle: boolean;
  links: ISidebarLink[];
}

const Sidebar: FC<IProps> = ({ title, showTitle, links }) => {
  const { t } = useTranslation();

  return (
    <SidebarContainer>
      {showTitle && <SidebarHeader>{title}</SidebarHeader>}
      <SidebarLinks>
        {links.map((x) => (
          <li key={x.key}>
            <SidebarLink to={x.to}>
              {x.text}
              <FontAwesomeIcon icon={x.icon} color={COLORS.sidebarColor} />
            </SidebarLink>
          </li>
        ))}
        <Logout>
          <div
            onClick={() => {
              logout();
              window.location.reload();
            }}
          >
            {t("header.logout")}
            <FontAwesomeIcon icon={faSignOutAlt} color={COLORS.sidebarColor} />
          </div>
        </Logout>
      </SidebarLinks>
    </SidebarContainer>
  );
};

export default Sidebar;
