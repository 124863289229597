import { AppThunkAction, AppThunkActionAsync } from "..";
import {
  listEffectLoad,
  listEffectReload,
  listEffectsCountCheck,
  listEffectsGetCheck,
  listEffectsGetCount,
  listEffectsGetNew,
  listEffectsGetParams,
} from "../listEffect";
import { selectClientChangeDemandsList } from "./selectors";
import * as actions from "./actions";
import { CancelToken } from "axios";
import clientDemandApi from "../../api/clientDemand";

export const clientChangeDemandListLoad =
  (reload: boolean): AppThunkAction =>
  async (dispatch, getState) => {
    const state = selectClientChangeDemandsList(getState());

    reload = listEffectReload(reload, state);
    if (reload) {
      dispatch(actions.clientChangeDemandsListReset());
    } else {
      const newState = listEffectLoad(state);

      dispatch(
        actions.clientChangeDemandsListLoad({
          newState: newState.newState,
          newCountState: newState.newCountState,
        })
      );
    }
  };

export const getClientChangeDemandList =
  (cancelToken: CancelToken): AppThunkAction =>
  async (dispatch, getState) => {
    const state = selectClientChangeDemandsList(getState());

    if (listEffectsGetCheck(state.state)) {
      return;
    }

    let newState = listEffectsGetNew(state);
    dispatch(
      actions.clientChangeDemandsListFetchRequest(newState.newCountState)
    );
    try {
      const params = listEffectsGetParams(state, newState.take);

      const demands = await clientDemandApi.getMyChangeDemands({
        cancelToken,
        params,
      });
      cancelToken.throwIfRequested();
      newState = listEffectsGetCount(newState, demands);

      dispatch(
        actions.clientChangeDemandsFetchSuccess({
          data: demands.data,
          newCountState: newState.newCountState,
          newCount: newState.newCount,
        })
      );
    } catch (err) {
      dispatch(
        actions.clientChangeDemandsListFetchError(
          cancelToken.reason !== undefined
        )
      );
    }
  };

export const getClientChangeDemandListCount =
  (cancelToken: CancelToken): AppThunkActionAsync =>
  async (dispatch, getState) => {
    const state = selectClientChangeDemandsList(getState());

    if (listEffectsCountCheck(state.countState)) {
      return;
    }

    dispatch(actions.clientChangeDemandsListCountRequest());
    try {
      const count = await clientDemandApi.getMyChangeDemandsCount({
        cancelToken,
      });
      cancelToken.throwIfRequested();

      dispatch(actions.clientChangeDemandsListCountSuccess(count.data.count));
    } catch (err) {
      dispatch(
        actions.clientChangeDemandsListCountError(
          cancelToken.reason !== undefined
        )
      );
    }
  };

export const changeChangeDemandListPage =
  (page: number): AppThunkAction =>
  async (dispatch) => {
    dispatch(actions.clientChangeDemandListPage(page));
  };

export const changeChangeDemandListOrder =
  (orderBy: string, orderDesc: boolean): AppThunkActionAsync =>
  async (dispatch) => {
    dispatch(actions.clientChangeDemandsLisOrder({ orderBy, orderDesc }));
  };
