import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AnyAction, combineReducers } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import * as ReselectModule from "reselect";
import { IClientState, clientReducer } from "./client";
import { clientHouseReducer, IClientHouseState } from "./clientHouse";
import { clientCareListReducer, IClientCareListState } from "./clientCareList";
import { clientCareReducer, IClientCareState } from "./clientCare";
import {
  clientDocumentListReducer,
  IClientDocumentListState,
} from "./clientDocumentList";
import {
  clientContactPersonListReducer,
  IClientContactPersonListState,
} from "./clientContactPersonList";
import {
  clientContactPersonReducer,
  IClientContactPersonState,
} from "./clientContactPerson";
import { demandReducer, IDemandState } from "./demand";
import {
  clientDemandListReducer,
  IClientDemandListState,
} from "./clientDemandList";
import {
  IPasswordStrengthState,
  passwordStrengthReducer,
} from "./passwordStrength";
import {
  clientFolderListReducer,
  IClientFolderListState,
} from "./clientFolderList";
import { clientFolderReducer, IClientFolderState } from "./clientFolder";
import {
  assistanceAddressFiltersReducer,
  IAssistanceAddressFiltersState,
} from "./assistanceAddressFilters";
import {
  assistanceAddressMapReducer,
  IAssistanceAddressMapState,
} from "./assistanceAddressMap";
import {
  assistanceAddressListReducer,
  IAssistanceAddressListState,
} from "./assistanceAddressList";
import {
  assistanceAddressReducer,
  IAssistanceAddressState,
} from "./assistanceAddress";
import {
  clientChangeDemandListReducer,
  IClientChangeDemandListState,
} from "./clientChangeDemandsList";
import { IChangeDemandState } from "./changeDemand";

export interface IApplicationState {
  passwordStrength: IPasswordStrengthState;
  client: IClientState;
  clientHouse: IClientHouseState;
  clientCareList: IClientCareListState;
  clientCare: IClientCareState;
  clientFolderList: IClientFolderListState;
  clientFolder: IClientFolderState;
  clientDocumentList: IClientDocumentListState;
  clientContactPersonList: IClientContactPersonListState;
  clientContactPerson: IClientContactPersonState;
  clientDemandList: IClientDemandListState;
  clientChangeDemandsList: IClientChangeDemandListState;
  demand: IDemandState;
  changeDemand: IChangeDemandState;
  assistanceAddressFilters: IAssistanceAddressFiltersState;
  assistanceAddressMap: IAssistanceAddressMapState;
  assistanceAddressList: IAssistanceAddressListState;
  assistanceAddress: IAssistanceAddressState;
}

export type AppThunkDispatch = ThunkDispatch<
  IApplicationState,
  undefined,
  AnyAction
>;
export type AppThunkAction<R = void> = ThunkAction<
  R,
  IApplicationState,
  undefined,
  AnyAction
>;
export type AppThunkActionAsync<R = void> = AppThunkAction<Promise<R>>;

export type AppSelector<R> = ReselectModule.Selector<IApplicationState, R>;
export type AppParametricSelector<P, R> = ReselectModule.ParametricSelector<
  IApplicationState,
  P,
  R
>;

export const useAppSelector: TypedUseSelectorHook<IApplicationState> =
  useSelector;
export const useAppDispatch = () => useDispatch<AppThunkDispatch>();

export const createRootReducer = combineReducers({
  passwordStrength: passwordStrengthReducer,
  client: clientReducer,
  clientHouse: clientHouseReducer,
  clientCareList: clientCareListReducer,
  clientCare: clientCareReducer,
  clientFolderList: clientFolderListReducer,
  clientFolder: clientFolderReducer,
  clientDocumentList: clientDocumentListReducer,
  clientContactPersonList: clientContactPersonListReducer,
  clientContactPerson: clientContactPersonReducer,
  clientDemandList: clientDemandListReducer,
  demand: demandReducer,
  changeDemand: demandReducer,
  assistanceAddressFilters: assistanceAddressFiltersReducer,
  assistanceAddressMap: assistanceAddressMapReducer,
  assistanceAddressList: assistanceAddressListReducer,
  assistanceAddress: assistanceAddressReducer,
  clientChangeDemandsList: clientChangeDemandListReducer,
});
