import { IItemIdName } from "./common";

export enum ClientIdentityType {
  IdentityCard = "identityCard",
  Passport = "passport",
  Unverified = "unverified",
}

export enum ClientHouseLivingType {
  Standard = "standard",
  PsychiatricHospital = "psychiatricHospital",
  OtherMedicalFacalities = "OtherMedicalFacilities",
  ZUVVTOS = "ZUVVTOS",
  SpecialPurposeApartment = "specialPurposeApartment",
  NonresidentalSpace = "nonresidentalSpace",
  ProtectedHousing = "protectedHousing",
  Azyl = "azyl",
  HalWayHouse = "halWayHouse",
  Dorm = "dorm",
  Homeless = "homeless",
  Hostel = "hostel",
}

export enum ClientHouseOwnershipType {
  Personal = "personal",
  Rent = "rent",
  RentalAgreementTermInfinite = "rentalAgreementTermInfinite",
  RentalAgreementTermFinite = "rentalAgreementTermFinite",
  CooperativeApartment = "cooperativeApartment",
  RentOffice = "rentOffice",
  LivingWithRelatives = "livingWithRelatives",
  LivingWithPartner = "livingWithPartner",
  LivingWithAcquaintances = "livingWithAcquaintances",
}

export enum ClientCareStateType {
  Preparation = "preparation",
  InProgress = "inProgress",
  Postponed = "postponed",
  PrematurelyFinished = "prematurelyFinished",
  Finished = "finished",
}

export interface IClient {
  id: number;
  firstName: string;
  lastName: string;
  maidenName?: string | null;
  title?: string;
  degree?: string | null;
  mobilePhone?: string | null;
  email?: string | null;
  phone?: string | null;
  dataBox?: string | null;
  identityType?: ClientIdentityType | null;
  identityCode?: string | null;
  birthDate?: Date | null;
  identificationNumber?: string | null;

  addressPermanentStreet?: string | null;
  addressPermanentHouseNumber?: number | null;
  addressPermanentLandRegistryNumber?: number | null;
  addressPermanentCity?: string | null;
  addressPermanentPostCode?: number | null;

  addressActualStreet?: string | null;
  addressActualHouseNumber?: number | null;
  addressActualLandRegistryNumber?: number | null;
  addressActualCity?: string | null;
  addressActualPostCode?: number | null;

  addressMailStreet?: string | null;
  addressMailHouseNumber?: number | null;
  addressMailLandRegistryNumber?: number | null;
  addressMailCity?: string | null;
  addressMailPostCode?: number | null;

  addressActualIsSame: boolean;
  addressMailIsSame: boolean;

  keyCaregiver: {
    id: number;
    name: string;
    email: string;
    mobileNumber: string;
    showEmail: boolean;
    showMobilePhoneNumber: boolean;
  } | null;

  healthInsurance?: string | null;

  contactPerson?: IClientContactPerson | null;

  clientDemandFiles?: {
    fileName: string;
    uploadedAt: Date;
    size: number;
  };
}

export interface IClientDemand {
  firstName: null | string;
  lastName: null | string;
  maidenName: null | string;
  title: null | string;
  degree: null | string;
  mobilePhone: null | string;
  phone: null | string;
  email: null | string;
  dataBox: null | string;
  identityType: null | string;
  identityCode: null | string;
  birthDate: null | Date;
  createdAt: null | Date;
  finishedAt: null | Date;
  clientDemandFiles?:
    | {
        fileName: string;
        uploadedAt: Date;
        size: number;
      }[]
    | null;
}

export interface IClientHouse {
  livingType?: ClientHouseLivingType | null | "";
  ownershipType?: ClientHouseOwnershipType | null | "";
  floorNumber?: number | null;
  conditions?: string | null;
  barriers?: string | null;
  personsInHousehold?: number | null;
  livingLength?: number | null;
  costPerMonth?: number | null;
  termination?: boolean | null;
}

export interface IClientCareItem {
  id: number;
  assistanceAddress: IItemIdName;
  careType: IItemIdName;
  from: Date;
  to?: Date | null;
  state: ClientCareStateType;
}

export interface IClientCare {
  id: number;
  assistance: IItemIdName;
  assistanceAddress: IItemIdName;
  assistanceCategoryListing: IItemIdName;
  careType: IItemIdName;
  from: Date;
  to?: Date | null;
  state: ClientCareStateType;
  note?: string | null;
}

export interface IClientDocumentItem {
  id: number;
  name: string;
  author: IItemIdName;
  createdAt: Date;
  lastEditedAt: Date;
  lastEditor: IItemIdName;
}

export interface IClientContactPerson {
  id: number;
  isActive: boolean;
  login?: string | null;
  type: IItemIdName;
  firstName: string;
  lastName: string;
  title?: string | null;
  degree?: string | null;
  mobilePhone?: string | null;
  email?: string | null;
  phone?: string | null;
}

export interface IClientChangeDemands {
  id: number;
  createdAt: Date;
  finishedAt: Date;
  state: string;
  filesCount: number;
  note: string;
  adminName: string;
}

export interface IClientCreateChangeDemand {
  created: boolean;
  uploadUrls: {
    url: string;
    filename: string;
  }[];
}
