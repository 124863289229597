import { connect } from "react-redux";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  getChangeDemand,
  selectChangeDemand,
  selectChangeDemandState,
} from "../../store/changeDemand";
import { IApplicationState } from "../../store";
import { StoreState } from "../../store/storeState";
import { useTranslation } from "react-i18next";

// TODO: create detail for change demand

interface IProps {
  changeDemandState: StoreState;
  changeDemand: {
    id: number;
    clientDemandId: number;
    firstName: string;
    lastName: string;
    maidenName: string;
    title: string | null;
    degree: string | null;
    mobilePhone: string | null;
    phone: string | null;
    email: string;
    dataBox: string | null;
    identityType: string | null;
    identityCode: string | null;
    birthDate: string; // assuming ISO 8601 format
    createdAt: string; // assuming ISO 8601 format
    finishedAt: string; // assuming ISO 8601 format
    clientDemandFiles: any[]; // Replace 'any' with a more specific type if possible
  };
  getChangeDemand(demandId: number): void;
}

const ChangeDemand: FC<IProps> = ({
  changeDemandState,
  changeDemand,
  getChangeDemand,
}) => {
  const { demandId } = useParams();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    getChangeDemand(parseInt(demandId!));
    setLoading(false);
  }, [demandId, getChangeDemand]);

  if (loading && changeDemandState === StoreState.Loading && !changeDemand) {
    return <h1>Loading...</h1>;
  }

  if (changeDemandState === StoreState.Error) {
    return <h1>Error</h1>;
  }

  return (
    <div>
      <h1> {t("changeDemand.title")} </h1>
      {/* <div>
        <h2> {t("changeDemand.demandInfo")} </h2>
        <p>
          <b> {t("changeDemand.demandId")}: </b> {changeDemand.id}
        </p>
        <p>
          <b> {t("changeDemand.clientDemandId")}: </b>{" "}
          {changeDemand.clientDemandId}
        </p>
        <p>
          <b> {t("changeDemand.firstName")}: </b> {changeDemand.firstName}
        </p>
        <p>
          <b> {t("changeDemand.lastName")}: </b> {changeDemand.lastName}
        </p>
        <p>
          <b> {t("changeDemand.maidenName")}: </b> {changeDemand.maidenName}
        </p>
        <p>
          <b> {t("changeDemand.title")}: </b> {changeDemand.title}
        </p>
        <p>
          <b> {t("changeDemand.degree")}: </b> {changeDemand.degree}
        </p>
      </div> */}
    </div>
  );
};

const mapStateToProps = (state: IApplicationState) => ({
  changeDemandState: selectChangeDemandState(state),
  changeDemand: selectChangeDemand(state),
});

const dispatchProps = {
  getChangeDemand,
};

export default connect(mapStateToProps, dispatchProps)(ChangeDemand);
