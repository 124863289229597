import { AppThunkActionAsync } from "..";
import clientDemandApi from "../../api/clientDemand";
import * as actions from "./actions";

export const getChangeDemand =
  (demandId: number): AppThunkActionAsync =>
  async (dispatch) => {
    dispatch(actions.changeDemandFetchRequest());
    try {
      const changeDemand = await clientDemandApi.getChangeDemand(demandId);
      dispatch(actions.changeDemandFetchSuccess(changeDemand.data));
    } catch (err) {
      dispatch(actions.changeDemandFetchError());
    }
  };
