import React, { createContext, FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-responsive-modal";
import {
  ModalContent,
  ModalHeader,
} from "../components/common/modal/CustomModalStyles";
import { Button } from "../styles/button";

export interface PromiseModalContextProps {
  openModal: (
    message: string,
    resolveAction: () => void,
    rejectAction: () => void
  ) => void;
}

export const PromiseModalContext = createContext<PromiseModalContextProps>({
  openModal: () => {},
});

const PromiseModalProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [resolveAction, setResolveAction] = useState<(() => void) | null>(null);
  const [rejectAction, setRejectAction] = useState<(() => void) | null>(null);
  const [message, setMessage] = useState<string>("");
  const { t } = useTranslation();

  const openModal = (
    message: string,
    resolve: () => void,
    reject: () => void
  ) => {
    setMessage(message);

    setResolveAction(() => () => {
      resolve();
      setMessage("");
    });
    setRejectAction(() => () => {
      reject();
      setMessage("");
    });
  };

  // Define a Modal component here or import it if you have a separate Modal component
  const ModalComponent = () => (
    <Modal
      open={Boolean(message)}
      onClose={() => {
        rejectAction?.();
      }}
    >
      <ModalHeader> {t("common.confirmation")} </ModalHeader>
      <ModalContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <h3> {message} </h3>
          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <Button onClick={() => resolveAction?.()}>
              {" "}
              {t("common.yes")}{" "}
            </Button>
            <Button ver="secondary" onClick={() => rejectAction?.()}>
              {t("common.no")}
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );

  return (
    <PromiseModalContext.Provider value={{ openModal }}>
      {children}
      {/* Render your modal component here */}
      <ModalComponent />
    </PromiseModalContext.Provider>
  );
};

export { PromiseModalProvider };
export const usePromiseModal = () => useContext(PromiseModalContext);
