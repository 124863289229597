import { FC } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Login from "./components/auth/Login";
import { IApplicationState } from "./store";
import { StoreState } from "./store/storeState";
import { getClient, selectClientState } from "./store/client";
import storageApi from "./utils/storage";
import LoaderPage from "./components/layout/LoaderPage";
import AuthRoute from "./components/auth/AuthRoute";
import ClientDetail from "./components/client/ClientDetail";
import SignDownDocument from "./components/signDownDocument/SignDownDocument";

interface IProps {
  clientState: StoreState;
  getClient(): void;
}

const Routes2: FC<IProps> = ({ clientState, getClient }) => {
  const tokenIsValid = storageApi.getTokenIsValid();

  if (!tokenIsValid || clientState === StoreState.Error) {
    return <Login />;
  }

  if (clientState === StoreState.None) {
    getClient();
    return <LoaderPage />;
  }

  if (clientState === StoreState.Loading) {
    return <LoaderPage />;
  }

  return (
    <Routes>
      <Route path="documentSignDown/:token" element={<SignDownDocument />} />
      <Route path="login" element={<Login />} />
      <Route
        path="*"
        element={
          <AuthRoute>
            <ClientDetail />
          </AuthRoute>
        }
      />
    </Routes>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    clientState: selectClientState(state),
  };
};

const mapDispachToProps = {
  getClient,
};

export default connect(mapStateToProps, mapDispachToProps)(Routes2);
