import { CancelToken } from "axios";
import { FC, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { DemandPriorityType, IDemandItem } from "../../models/demand";
import { IApplicationState } from "../../store";
import {
  changeClientDemandListOrder,
  changeClientDemandListPage,
  getClientDemandList,
  getClientDemandListCount,
  IClientDemandListState,
  clientDemandListLoad,
  selectClientDemandList,
} from "../../store/clientDemandList";
import {
  clientChangeDemandListLoad,
  getClientChangeDemandList,
  getClientChangeDemandListCount,
  IClientChangeDemandListState,
  selectClientChangeDemandsList,
  changeChangeDemandListPage,
  changeChangeDemandListOrder,
} from "../../store/clientChangeDemandsList";
import Grid from "../common/grid/Grid";
import { GridIconEdit } from "../common/grid/GridIcons";
import { TableCol } from "../common/grid/TableCol";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { IClientChangeDemands } from "../../models/client";
import { format } from "date-fns";
import { DATE_FORMAT } from "../../utils/consts";

interface IProps {
  prov: IClientDemandListState;
  prov2: IClientChangeDemandListState;
  // demands
  getClientDemandList(cancelToken: CancelToken): void;
  getClientDemandListCount(cancelToken: CancelToken): void;
  changeClientDemandListOrder(orderBy: string, orderDesc: boolean): void;
  changeClientDemandListPage(page: number): void;
  clientDemandListLoad(reload: boolean): void;
  // change demands
  clientChangeDemandListLoad(reload: boolean): void;
  getClientChangeDemandList(cancelToken: CancelToken): void;
  getClientChangeDemandListCount(cancelToken: CancelToken): void;
  changeChangeDemandListPage(page: number): void;
  changeChangeDemandListOrder(orderBy: string, orderDec: boolean): void;
}

const ClientDemand: FC<IProps> = ({
  prov,
  prov2,
  // demands
  getClientDemandList,
  getClientDemandListCount,
  changeClientDemandListOrder,
  changeClientDemandListPage,
  clientDemandListLoad,
  // change demands
  clientChangeDemandListLoad,
  getClientChangeDemandList,
  getClientChangeDemandListCount,
  changeChangeDemandListPage,
  changeChangeDemandListOrder,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    clientDemandListLoad(false);
  }, [clientDemandListLoad]);

  useEffect(() => {
    clientChangeDemandListLoad(false);
  }, [clientChangeDemandListLoad]);

  const handleRenderData = (item: IDemandItem): ReactElement => {
    return (
      <>
        <TableCol>{t("demand.states." + item.state)}</TableCol>
        <TableCol>
          <span
            className={
              item.priority === DemandPriorityType.High ? "error" : undefined
            }
          >
            {t("demand.priorities." + item.priority)}
          </span>
        </TableCol>
        <TableCol>{item.name}</TableCol>
        <TableCol>{item.assignedName}</TableCol>
        <TableCol>
          <NavLink to={`/demand/${item.id}`}>
            <GridIconEdit />
          </NavLink>
        </TableCol>
      </>
    );
  };

  const handleRenderData2 = (item: IClientChangeDemands): ReactElement => {
    return (
      <>
        <TableCol>{t("changeDemand.states." + item.state)}</TableCol>
        <TableCol>{t("client.demand.changePersonalData")}</TableCol>
        <TableCol>{format(item.createdAt, DATE_FORMAT)}</TableCol>
        <TableCol>{item.adminName}</TableCol>
        <TableCol>{item.note}</TableCol>
        <TableCol />
      </>
    );
  };

  const handleRowClick = (item: IDemandItem) => {
    navigate(`/demand/${item.id}`, {
      state: {
        type: "demand",
      },
    });
  };

  const handleRowClick2 = (item: IClientChangeDemands) => {
    return;

    // TODO: Here will navigate into detail of change demand

    // eslint-disable-next-line no-unreachable
    navigate(`/changeDemand/${item.id}`, {
      state: {
        type: "change",
      },
    });
  };

  return (
    <>
      <h1>{t("client.demand.detailTitle")}</h1>
      <Grid<IDemandItem>
        headers={[
          { captionStr: "client.demand.state" },
          { captionStr: "client.demand.priority" },
          { captionStr: "client.demand.name" },
          { captionStr: "client.demand.assignedName" },
          {},
        ]}
        renderData={handleRenderData}
        getData={getClientDemandList}
        getCount={getClientDemandListCount}
        changeOrder={changeClientDemandListOrder}
        changePage={changeClientDemandListPage}
        onRowClick={handleRowClick}
        prov={prov}
      />
      <br />
      <h1> {t("client.demand.changeDemands")} </h1>
      <Grid<IClientChangeDemands>
        headers={[
          { captionStr: "client.demand.state", orderName: "state" },
          { captionStr: "client.demand.requestName" },
          { captionStr: "client.demand.createdAt", orderName: "createdAt" },
          {
            captionStr: "client.demand.assignedName",
          },
          { captionStr: "client.demand.rejectReason" },
          {},
        ]}
        renderData={handleRenderData2}
        getData={getClientChangeDemandList}
        getCount={getClientChangeDemandListCount}
        changeOrder={changeChangeDemandListOrder}
        changePage={changeChangeDemandListPage}
        onRowClick={handleRowClick2}
        prov={prov2}
      />
    </>
  );
};

const mapStateToProps = (state: IApplicationState) => {
  return {
    prov: selectClientDemandList(state),
    prov2: selectClientChangeDemandsList(state),
  };
};

const mapDispachToProps = {
  // demands
  getClientDemandList,
  getClientDemandListCount,
  changeClientDemandListOrder,
  changeClientDemandListPage,
  clientDemandListLoad,
  // change demands
  clientChangeDemandListLoad,
  getClientChangeDemandList,
  getClientChangeDemandListCount,
  changeChangeDemandListPage,
  changeChangeDemandListOrder,
};

export default connect(mapStateToProps, mapDispachToProps)(ClientDemand);
