import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { SIZES } from "../../styles/sizes";

export const SidebarContainer = styled.div`
  display: flex;
  height: 100%;
  width: max-content;
  overflow-y: auto;
  color: var(--sidebar-color);
  background-color: var(--sidebar-bg);
  display: none;

  @media screen and (min-width: ${SIZES.laptop}) {
    display: block;
  }
`;

export const SidebarHeader = styled.h1`
  padding: 1rem;
  text-align: center;
  color: var(--sidebar-color);
`;

export const SidebarLinks = styled.ul`
  flex-flow: row;
  align-items: center;
  list-style: none;
  height: 100svh;
  position: relative;

  svg {
    transition: all 0.15s ease-in-out;
    color: var(--sidebar-color) !important;

    align-items: left;
    position: absolute;
    right: 1rem;
  }

  a {
    text-decoration: none;
    background-color: var(--sidebar-bg);
  }

  a:hover {
    color: var(--sidebar-color-hover) !important;
    background-color: var(--sidebar-bg-hover) !important;

    svg {
      color: var(--sidebar-color-hover) !important;
    }
  }

  a.active {
    color: var(--sidebar-color-active) !important;
    background-color: var(--sidebar-bg-active) !important;

    svg {
      color: var(--sidebar-color-active) !important;
    }
  }
`;

export const SidebarLink = styled(NavLink)`
  width: 100%;
  padding: 0.5rem 3rem 0.5rem 2rem;
  margin: 0.25rem 0;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  white-space: nowrap;
  text-transform: uppercase;
  color: var(--sidebar-color);
  font-family: Fira Sans;
  font-size: 1rem;
  transition: all 0.15s ease-in-out;
`;

export const Logout = styled.div`
  width: 100%;
  padding: 0.5rem 3rem 0.5rem 2rem;
  margin: 1rem 0;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  cursor: pointer;

  white-space: nowrap;
  text-transform: lowercase;
  color: var(--sidebar-color);
  font-family: Fira Sans;
  font-size: 1rem;
  transition: all 0.15s ease-in-out;

  svg {
    transition: all 0.15s ease-in-out;
    color: var(--sidebar-color) !important;

    align-items: left;
    right: 1rem;
  }

  &:hover {
    color: var(--sidebar-color-hover) !important;
    background-color: var(--sidebar-bg-hover) !important;

    svg {
      color: var(--sidebar-color-hover) !important;
    }
  }
`;
