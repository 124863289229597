import { FC, useEffect, useState } from "react";
import { Pdf } from "signify-pdf";
import "signify-pdf/dist/index.css";
import PromiseModalWithText from "../common/modal/PromiseModalWithText";
import { useNavigate, useParams } from "react-router";
import { promiseToast, promiseToastSave } from "../../utils/toasts";
import digitalSignatureApi from "../../api/digitalSignature";
import documentApi from "../../api/document";
import { useTranslation } from "react-i18next";
import { PDFDocument } from "pdf-lib";
import clientApi from "../../api/client";
import bucketApi from "../../api/bucket";
import moment from "moment-timezone";

const SignDownDocument: FC = () => {
  const { token } = useParams();
  const [fileToSign, setFileToSign] = useState<File | null>(null);
  const [code, setCode] = useState<string>("");
  const [fileData, setFileData] = useState<{
    clientId: number;
    documentId: number;
  } | null>(null);
  const [codeModalData, setCodeModalData] = useState<{
    resolve: (value: string) => void;
    reject: () => void;
    title: string;
  } | null>(null);
  const [stamp, setStamp] = useState<{
    name: string;
    date: string;
  } | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSignDownProcess = async () => {
    if (!token) return;

    try {
      const codePromise = new Promise<string>((resolve, reject) => {
        setCodeModalData({
          resolve,
          reject,
          title: t("pdfSignature.enterCode"),
        });
      });

      const code = await codePromise;

      promiseToast(
        async () => {
          const fileData = await digitalSignatureApi.checkCodeValidity({
            code,
            sha256: token,
          });

          setFileData({
            clientId: fileData.data.clientId,
            documentId: fileData.data.documentId,
          });
          setCode(fileData.data.digitalSignature);

          const date = new Date();

          const timezoneOffset = date.getTimezoneOffset();
          const offsetHours = Math.abs(timezoneOffset / 60);
          const offsetSign = timezoneOffset > 0 ? "-" : "+";

          const formattedOffset =
            offsetSign +
            String(offsetHours).padStart(2, "0") +
            ":" +
            String(timezoneOffset % 60)
              .padStart(2, "0")
              .padStart(3, "0");

          const dateWithZone = moment.utc(date).format("DD-MM-YYYY HH:mm:ss");
          const formattedDate = `${dateWithZone} ${formattedOffset}`;

          setStamp({
            name: fileData.data.clientFullName,
            date: formattedDate,
          });

          const fileBytes = await documentApi.getDocumentBytes({
            clientId: fileData.data.clientId,
            documentId: fileData.data.documentId,
          });

          const decodedBytes = atob(fileBytes.data);
          const byteArray = new Uint8Array(decodedBytes.length);
          for (let i = 0; i < decodedBytes.length; i++) {
            byteArray[i] = decodedBytes.charCodeAt(i);
          }

          const blob = new Blob([byteArray], {
            type: "application/octet-stream",
          });

          const file = new File([blob], "downloadedFile", {
            type: "application/octet-stream",
          });

          const newBlob = new Blob([file], { type: "application/pdf" });
          const pdfFile = new File([newBlob], file.name, {
            type: "application/pdf",
          });

          setFileToSign(pdfFile);
        },
        t("smsVerification.pending"),
        t("smsVerification.success"),
        t("smsVerification.error")
      );
    } catch (e) {
      console.log(e);
      navigate("/");
    } finally {
      setCodeModalData(null);
    }
  };

  const handleSignDownComplete = async (file: PDFDocument) => {
    try {
      await promiseToastSave(async () => {
        if (!fileData) return;

        const uploadUrl = await clientApi.getClientDocumentUploadUrl(
          fileData.clientId,
          undefined,
          fileToSign?.name!,
          fileData.documentId.toString() ?? ""
        );

        const blob = await file.save();
        const fileToUpload = new File([blob], fileToSign?.name!, {
          type: "application/pdf",
        });

        await bucketApi.uploadDocument(uploadUrl.data, fileToUpload);

        await clientApi.updateSignedDocument(fileData.documentId);

        await digitalSignatureApi.sendTimeOfSigning({
          sha256: token!,
          dateOfSign: stamp?.date ?? "",
        });

        navigate("/");
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleSignDownProcess();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {!!fileToSign && (
        <Pdf
          open={!!fileToSign}
          file={fileToSign}
          onClose={() => setFileToSign(null)}
          code={code}
          texts={{
            save: t("pdfSignature.save"),
            reset: t("pdfSignature.reset"),
            close: t("pdfSignature.close"),
            addSignature: t("pdfSignature.addSignature"),
            placeHolderTooltip: t("pdfSignature.placeholderTooltip"),
            download: t("pdfSignature.download"),
            signDown: t("pdfSignature.signDown"),
            readPdfHelper: t("pdfSignature.readPdfHelper"),
            reded: t("pdfSignature.reded"),
          }}
          customStyles={{
            button: {
              padding: "4px 8px",
              fontSize: "1.2rem",
              border: "none",
              outline: "none",
              borderRadius: "5px",
            },
          }}
          customPdfDownloadFunction={handleSignDownComplete}
          stamp={`${stamp?.name ?? ""} ${stamp?.date ?? ""} `}
        />
      )}
      {!!codeModalData && (
        <PromiseModalWithText
          title={codeModalData?.title || ""}
          resolve={codeModalData?.resolve || (() => {})}
          reject={codeModalData?.reject || (() => {})}
        />
      )}
    </div>
  );
};

export default SignDownDocument;
