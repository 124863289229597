import * as ReselectModule from "reselect";
import { IApplicationState } from "..";
import { StoreState } from "../storeState";

export const selectChangeDemandState: ReselectModule.Selector<
  IApplicationState,
  StoreState
> = (state) => state.changeDemand.state;

export const selectChangeDemand: ReselectModule.Selector<
  IApplicationState,
  any | null
> = (state) => state.changeDemand.data;
