import { createReducer } from "@reduxjs/toolkit";
import { StoreState, StoreStateDontLoad } from "../storeState";
import * as actions from "./actions";
import { IClientChangeDemandListState } from "./types";

const initialState = {
  state: StoreState.None,
  data: [],
  countState: StoreStateDontLoad.DontLoad,
  count: 0,
  page: 0,
  orderBy: "state",
  orderDesc: false,
  filter: { search: undefined, isOpen: false },
} as IClientChangeDemandListState;

export const clientChangeDemandListReducer = createReducer(
  initialState,
  (builder) =>
    builder
      .addCase(actions.clientChangeDemandsFetchSuccess, (state, action) => ({
        ...state,
        state: StoreState.Loaded,
        lastLoaded: new Date(),
        data: action.payload.data,
        countState: action.payload.newCountState,
        count: action.payload.newCount,
      }))
      .addCase(
        actions.clientChangeDemandsListFetchRequest,
        (state, action) => ({
          ...state,
          state: StoreState.Loading,
          countState: action.payload,
        })
      )
      .addCase(actions.clientChangeDemandsListFetchError, (state, action) => ({
        ...state,
        state: action.payload ? StoreState.Cancel : StoreState.Error,
      }))
      .addCase(actions.clientChangeDemandsListCountRequest, (state) => ({
        ...state,
        countState: StoreStateDontLoad.Loading,
      }))
      .addCase(
        actions.clientChangeDemandsListCountSuccess,
        (state, action) => ({
          ...state,
          countState: StoreStateDontLoad.Loaded,
          count: action.payload,
        })
      )
      .addCase(actions.clientChangeDemandsListCountError, (state, action) => ({
        ...state,
        countState: action.payload
          ? StoreStateDontLoad.Cancel
          : StoreStateDontLoad.Error,
      }))
      .addCase(actions.clientChangeDemandListPage, (state, action) => ({
        ...state,
        state: StoreState.None,
        page: action.payload,
      }))
      .addCase(actions.clientChangeDemandsLisOrder, (state, action) => ({
        ...state,
        state: StoreState.None,
        orderBy: action.payload.orderBy,
        orderDesc: action.payload.orderDesc,
        page: 0,
      }))
);
