export enum DemandStateType {
  New = "new",
  Finished = "finished",
  Canceled = "canceled",
}

export enum DemandPriorityType {
  Low = "low",
  Medium = "medium",
  High = "high",
}

export interface IDemandItem {
  id: number;
  name?: string | null;
  state: DemandStateType;
  priority: DemandPriorityType;
  assignedName?: string | null;
}

export interface IDemand {
  id: number;
  name?: string | null;
  state: DemandStateType;
  priority: DemandPriorityType;
  description?: string | null;

  assignedUserId?: number | null;
  assignedUserName?: string | null;

  assignedGroupId?: number | null;
  assignedGroupName?: string | null;
}

export interface IChangeDemand {
  id: number;
  createdAt: Date;
  finishedAt?: Date | null;
  state: string;
  filesCount: number;
}

interface IAddress {
  street?: string | null;
  houseNumber?: string | null;
  landRegistryNumber?: string | null;
  city?: string | null;
  postCode?: string | null;
}

interface IDemandFile {
  fileName: string;
  size: number;
  uploadedAt: Date;
}

export interface IClientDemandType {
  firstName: string | null;
  lastName: string | null;
  maidenName: string | null;
  title: string | null;
  degree: string | null;
  mobilePhone: string | null;
  phone: string | null;
  email: string | null;
  dataBox: string | null;
  identityType: string | null;
  identityCode: string | null;
  birthDate: Date | null;
  createdAt: Date | null;
  finishedAt: Date | null;
  permanentAddress: IAddress;
  actualAddress?: IAddress | null;
  mailAddress?: IAddress | null;
  addressActualIsSame: boolean;
  addressMailIsSame: boolean;
  healthInsuranceId: number | null;
  clientDemandFiles: IDemandFile[] | null;
}
